@import "../shared/variables.scss";

@property --fill-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    pointer-events: none
}

.logoSection {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s, left 0.3s, top 0.3s, height 0.3s;
    height: 60vh;
    animation: intro 0.5s;

    @keyframes intro {
        from {
            opacity: 0;
            transform: translate(-50%, -50%) scale(0);
        }

        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    h1 {
        color: $white;
    }

    &.menu {
        //transform: translate(-50%, -60%);
        top: 45%;
        height: 45vh;
    }

    &.topLeft {
        transform: translate(0, 0);
        top: 2vh;
        left: 2vh;
        height: 15vh;
    }

    .connectingMessage {
        position: absolute;
        color: $white;
        font-size: 5vh;
        font-family: "Futura-PT";
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s;
        width: min-content;
        left: calc(50% + 1.5vh);
        transform: translateX(-50%);

        &.show {
            opacity: 1;
        }
    }

    .logo {
        height: 100%;
    }
}

.videoPreLoad {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10vh;
    width: 19vh;
    margin: 0 auto;
    border-radius: 1vh;
    box-shadow: 0 0 0 0.4vh black;
    overflow: hidden;

    .reactPlayer {
        background-color: $off-black;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #1E236E;
    //background-image: url('../images/Background Studio.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    &.showBgImage {
        //background-image: url('../images/Background Studio.png');
    }

    .mainBackground {
        background-color: #1E236E;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .menuBackground {
        background-color: #1E236E;
        /*        //background-image: url('../images/BG.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;*/
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s;
        animation: fadeIn 1s;

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &.show {
            opacity: 1;
        }
    }

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 2vh;
        top: 2vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        animation: sideIn 0.5s;

        @keyframes sideIn {
            from {
                transform: translateX(100%);
            }

            to {
                transform: translateX(0);
            }
        }

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;
            gap: 0.5vh;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Impact";
                color: $off-white;
            }

            .code {
                font-size: 4vh;
                color: #ECEF66;
            }

            .iconsBox {
                display: flex;
                flex-direction: row;
                margin-left: auto;
                margin-top: 0.5vh;
                width: fit-content;
                height: fit-content;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    pointer-events: initial;
                    padding-left: 1vh;
                    transition: transform 0.2s;

                    &:hover {
                        transform: scale(1.1);
                    }

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .startButtonSection {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s;

        .mainButton {
            transform: translateY(60vh);
            transition: transform 0.3s;
        }

        .toggle {
            transform: translateY(60vh);
            transition: transform 0.3s;
        }

        &.show {
            .mainButton {
                transform: translateY(0);

                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
            }

            .toggle {
                transform: translateY(0);
                transition-delay: 0.2s;
            }
        }
    }

    .licences {
        position: absolute;
        width: 90vw;
        left: 50%;
        bottom: 8%;
        transform: translate(-50%, 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: opacity 0.3s;
        opacity: 0;
        gap: 0.3vh;

        .text {
            font-size: 1.8vh;
            -webkit-text-stroke: 0.4vh black;
        }

        &.show {
            opacity: 1;
        }
    }

    .tutorialSection {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        transition: transform 0.3s;
    }

    .timer {
        position: absolute;
        top: calc(10% + 10px);
        width: 14vh;
        font-family: "Impact";
        line-height: 1;
        padding-top: 1vh;
        padding-bottom: 1vh;
        font-size: 5vh;
        text-align: center;
        background-color: #0A0F41;
        color: white;
        left: 50%;
        border-radius: 0.5vh;
        box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh #FFE849, 0 0 0 1.5vh black;
        transform: translate(-50%, 0) scale(0);
        transition: transform cubic-bezier(.45, -0.31, .97, .64) 0.3s, opacity 0.3s, box-shadow 0.3s;
        opacity: 0;

        &.show {
            transform: translate(-50%, 0) scale(1);
            transition: transform cubic-bezier(.17, .67, .47, 1.14) 0.3s, opacity 0.3s;
            opacity: 1;
        }

        &.paused {
            box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh grey, 0 0 0 1.5vh black;
            animation: pulse 0.5s infinite;

            @keyframes pulse {
                0% {
                    color: white;
                }

                50% {
                    color: transparent;
                }

                100% {
                    color: white
                }
            }
        }
    }

    .catchphraseSection {
        position: absolute;
        width: 100%;
        height: 100%;

        &.show {
            .border {
                transform: scale(1);
                transition: transform 0.3s cubic-bezier(.17, .67, .2, 1.3), opacity 0.3s, background-image 0.5s;
                opacity: 1;
            }
        }

        .timer {
            top: 4%;
        }

        .border {
            position: relative;
            height: 65vh;
            width: 115.55vh;
            //width: fit-content;
            top: 16%;
            margin: 0 auto;
            border-radius: 2vh;
            box-shadow: 0 0 0 0.5vh black;
            padding: 2vh;
            transform: scale(0);
            opacity: 0;
            background-image: conic-gradient( from 0deg, var(--team-colour) 0deg var(--fill-angle), white 0deg 360deg );
            transition: transform 0.6s cubic-bezier(.8, -0.3, .67, 0.3), opacity 0.6s, background-image 0.5s;
            --fill-angle: 0deg;

            &.buzzedIn {
                --fill-angle: 360deg;
                transition: transform 0.3s, opacity 0.3s, --fill-angle 1s linear;
            }

            .roundDisplay {
                position: fixed;
                top: -1.5vh;
                left: 50%;
                transform: translateX(-50%);
                color: white;
                text-align: center;
                padding: 0.5vh;
                border-radius: 0.5vh;
                background-color: #0A0F41;
                box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh var(--team-colour), 0 0 0 1.5vh black;

                .text {
                    font-size: 3vh;
                    font-family: "Impact";
                    text-transform: uppercase;
                }
            }

            .videoPlayer {
                position: relative;
                height: 100%;
                width: 100%;
                margin: 0 auto;
                border-radius: 1vh;
                box-shadow: 0 0 0 0.4vh black;
                overflow: hidden;

                .reactPlayer {
                    background-color: $off-black;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                .answer {
                    position: absolute;
                    font-family: "Impact";
                    text-transform: capitalize;
                    line-height: 1;
                    bottom: 0;
                    font-size: 5vh;
                    text-align: center;
                    left: 50%;
                    transform: translate(-50%, 120%);
                    padding: 2vh 3vh;
                    border-radius: 2vh 2vh 0 0;
                    transition: transform 0.3s;
                    color: white;
                    background-color: #0A0F41;
                    box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh var(--team-colour), 0 0 0 1.5vh black;


                    &.show {
                        transform: translate(-50%, 0);
                    }
                }

                .gridReveal {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    .reveal {
                        position: relative;
                        --border: 1vh;
                        background-color: $off-black;
                        width: 100%;
                        height: 100%;
                        transition: opacity 0.5s;
                        margin: -1px;
                        overflow: hidden;
                        object-position: 0 0;
                        object-fit: none;

                        img {
                            position: absolute;
                            width: 200%;
                            height: 200%;
                            object-fit: cover;
                            object-position: 0 0;
                            transition: object-position 0.5s;
                        }

                        &:nth-child(2) {
                            img {
                                top: 0;
                                right: 0;
                            }
                        }

                        &:nth-child(3) {
                            img {
                                bottom: 0;
                                left: 0;
                            }
                        }

                        &:nth-child(4) {
                            img {
                                bottom: 0;
                                right: 0;
                            }
                        }

                        &.hide {
                            opacity: 0;
                        }
                    }
                }

                .catchphraseScreen {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    transform: translateY(-100%);
                    transition: transform 0.3s;

                    &.show {
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    .moneyDrop {
        position: absolute;
        width: 15vh;
        height: 58vh;
        top: calc(50% - 2vh);
        left: calc(50% - 66vh);
        transform: translate(-50vw, -50%);
        transition: transform 0.3s;
        background-color: white;
        box-shadow: 0 0 0 0.5vh black;
        border-radius: 1.5vh;

        &.show {
            transform: translate(-50%, -50%);
        }

        .bar {
            position: relative;
            width: calc(100% - 2vh);
            height: calc(100% - 2vh);
            margin: 1vh;
            background-color: #0a0f41;
            border-radius: 1vh;
            box-shadow: 0 0 0 0.5vh black, -1vh -1vh 2vh 0 #FFFFFF33 inset, 1vh 1vh 2vh 0 #000000AA inset;
            overflow: hidden;

            .moneyDropAmount {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                height: var(--fill);
                min-height: 5vh;
                transition: height 1s linear;
                font-family: "Impact";
                opacity: 0.6;

                .face {
                    height: 100%;
                }
            }

            .fill {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: var(--fill);
                background: rgb(46,64,231);
                background: linear-gradient(90deg, rgba(46,64,231,1) 0%, rgba(32,47,186,1) 40%, rgba(32,47,186,1) 60%, rgba(46,64,231,1) 100%);
                box-shadow: 0 -0.5vh 1vh 0 rgba(46,64,231,1);
                transition: height 1s linear;
            }

            .line {
                position: absolute;
                width: 100%;
                height: 0.5vh;
                background: var(--team-colour);
                bottom: (var(--pos));
                box-shadow: 0 0 1vh 0 var(--team-colour);
                border-radius: 0.25vh;
                opacity: 0;

                &.show {
                    opacity: 1;
                }

                .text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-family: "Impact";
                    transform: translate(-50%, -50%);
                    font-size: 3vh;
                    text-align: center;
                    white-space: nowrap;
                    -webkit-text-stroke: 0.8vh black;
                }
            }
        }

        .labels {
            position: absolute;
            top: 1vh;
            left: -100%;
            width: 100%;
            height: calc(100% - 2vh);

            .label {
                position: absolute;
                left: 50%;
                bottom: calc(var(--pos) - 3vh);
                font-family: "Impact";
                text-transform: uppercase;
                transform: translate(-50%,-50%);
                text-align: center;
                white-space: nowrap;
                opacity: 0;

                &.show {
                    opacity: 1;
                }

                .text {
                    font-size: 3vh;
                    -webkit-text-stroke: 0.5vh black;
                }
            }
        }
    }

    .roundSummarySection {
        position: absolute;
        width: 100%;
        height: 100%;

        .title {
            font-size: 6vh !important;
            -webkit-text-stroke: 1vh black !important;
        }

        &.show {
            .scoreBars {
                .scoreBar {
                    height: var(--score);
                    transform: scale(1);
                    opacity: 1;
                    transition: height 1s 0.3s, transform 0.3s 0.2s, opacity 0.3s 0.2s;
                }
            }

            .notablePlayers {
                .player {
                    transform: translateY(0);
                }
            }
        }

        .scoreBars {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 90%;
            height: 23vh;
            transition: transform ease-in 0.3s 0.1s;

            .scoreBar {
                position: relative;
                width: 10%;
                height: 3vh;
                background-color: var(--team-colour);
                border-radius: 1vh;
                margin: 1vh;
                box-shadow: 0 0 0 0.5vh black;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                transform: scale(0);
                transform-origin: bottom;
                opacity: 0;
                transition: height 0s 0.3s, transform 0.3s 0.2s, opacity 0.3s 0.2s;

                .teamName {
                    position: absolute;
                    bottom: -4vh;
                    text-align: center;
                    font-size: 2.5vh;
                    font-family: "Impact";
                    color: white;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                .score {
                    position: absolute;
                    bottom: 0;
                    line-height: 1.1;
                    text-align: center;
                    font-size: 4.5vh;
                    font-family: "Impact";
                    color: white;
                    text-transform: uppercase;
                }
            }
        }

        .notablePlayers {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 70%;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            .player {
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                transform: translateY(100%);
                transition: transform 0.3s;

                &:nth-child(2) {
                    transition-delay: 0.1s;
                }

                &:nth-child(3) {
                    transition-delay: 0.2s;
                }

                .text {
                    margin: 4vh;
                    font-size: 3vh;
                    white-space: nowrap;
                }
            }
        }
    }

    .titles {
        position: absolute;
        font-family: "Impact";
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        padding: 15px;
        margin: 0;
        line-height: 0.9;
        font-size: 3vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 1vh;

        .title {
            font-size: 12vh;
            -webkit-text-stroke: 1.3vh black;
            transform: scale(0);
            opacity: 0;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3), opacity 0.3s;
        }

        .subTitle {
            font-size: 4vh;
            -webkit-text-stroke: 1vh black;
            transform: scale(0);
            opacity: 0;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3), opacity 0.3s;
        }
    }

    .show {
        .title {
            transform: scale(1);
            opacity: 1;
            transition: transform 0.3s 0.2s cubic-bezier(.17, .67, .2, 1.3), opacity 0.3s 0.2s;
        }

        .subTitle {
            transform: scale(1);
            opacity: 1;
            transition: transform 0.3s cubic-bezier(.17, .67, .2, 1.3), opacity 0.3s;
        }
    }

    .gameSelectionSection {

        .buttons {
            position: absolute;
            left: 50%;
            top: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translate(-50%, -50%);

            .mainButton {
                transform: translateY(60vh);
                transition: transform 0.3s;
            }
        }

        &.show {
            .buttons {
                .mainButton {
                    transform: translateY(300%);

                    &:nth-child(2) {
                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }

    .selectingTeamsSection {
        position: absolute;
        width: 100%;
        height: 100%;

        .mainButton {
            position: absolute;
            bottom: 10%;
            left: calc(50% - 2vh);
            transform: translate(-50%, 400%);
            font-family: "Futura-PT" !important;
            font-size: 4vw;
            transition: transform 0.3s;

            &.show {
                transform: translateX(-50%);
            }
        }

        .instruction {
            position: absolute;
            bottom: 26%;
            left: 50%;
            transform: translate(-50%, 0) scale(0);
            font-family: "Futura-PT" !important;
            -webkit-text-stroke: 0.5vh black;
            font-size: 3vh;
            color: white;
            text-align: center;
            background-color: rgba(1,1,1,0.75);
            border-radius: 0.6vh;
            box-shadow: 0 0 0 1.5vh rgba(1,1,1,0.75);
            opacity: 0;
            transition: transform 0.3s, opacity 0.3s;
        }

        .unassigned {
            display: grid;
            grid-auto-flow: column dense;
            grid-template-rows: 1fr 1fr;
            position: absolute;
            height: auto;
            left: 50%;
            top: calc(50% + 5%);
            transform: translate(-50%, -50%);
            color: $off-black;
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            gap: 4vh;

            .playerTag {
                transform: translateX(-50%) scale(0);
                opacity: 0;
                transition: transform 0.3s, opacity 0.3s;
            }
        }

        .teams {
            display: flex;
            flex-direction: column;
            position: absolute;
            height: 100%;
            //width: 25%;
            padding: 5vh;
            left: 0;
            top: 20%;
            gap: 10%;

            &.right {
                left: unset;
                right: 0;

                .team {
                    transform: translateX(150%);
                }
            }

            .team {
                position: relative;
                height: 30vh;
                width: 30vh;
                //aspect-ratio: 1/1;
                border-radius: 10px;
                background-color: var(--team-colour);
                box-shadow: 0 0 0 0.5vh black;
                transition: transform 0.3s;
                transform: translateX(-150%);

                &:nth-child(2) {
                    transition-delay: 0.1s;
                }

                .title {
                    position: absolute;
                    bottom: -1.8vh;
                    text-align: center;
                    border-radius: 6px;
                    color: white;
                    font-size: 3vh !important;
                    width: 100%;
                }

                .teamLetter {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    opacity: 0.5;
                    width: 1ch;
                    overflow: hidden;
                    font-size: 10vw;
                    font-family: "Impact";
                    text-align: center;
                    line-height: 1;
                }

                .players {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 4vh;
                }
            }
        }

        &.show {

            .playerTag {
                transform: translateX(-50%) scale(1);
                opacity: 1;
            }

            .instruction {
                transform: translate(-50%, 0) scale(1);
                opacity: 1;
            }

            .teams {
                .team {
                    transform: translateX(0);
                }
            }
        }
    }

    .roundIntroSection {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .titles {
            top: 27%;

            .title {
                font-size: 16vh;
                -webkit-text-stroke: 1.5vh black;
            }

            .subTitle {
                font-size: 6vh;
                -webkit-text-stroke: 1vh black;
            }
        }
    }


    .mainButton {
        position: relative;
        padding: 0.5vh;
        margin: 2vh;
        min-width: 20vw;
        font-family: "Futura-PT";
        background-color: #D7364D;
        border-radius: 0.5vh;
        border: none;
        box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh #FFE849, 0 0 0 1.5vh black;
        transition: background-color 0.3s;
        pointer-events: initial;

        &.alt {
            background-color: #B8DEE8;
        }

        &:not(:disabled):hover {
            background-color: #FFD700;

            .text {
                transform: translateY(-0.2vh);
            }
        }

        &.smaller {
            .text {
                font-size: 3vh;
                -webkit-text-stroke: 0.6vh black;
            }
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .text {
        position: relative;
        font-size: 4.2vh;
        //font-family: "Futura-PT";
        //font-weight: bold;
        line-height: 1;
        color: black;
        -webkit-text-stroke: 0.7vh black;
        transition: transform 0.3s;
        pointer-events: none;

        .face {
            position: absolute;
            color: white;
            -webkit-text-stroke: initial;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 100%;
            color: white;
        }
    }

    .toggle {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin: 2vh;
        pointer-events: initial;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: white;
            margin: 0;
            font: inherit;
            width: 5vh;
            height: 5vh;
            border-radius: 0.5vh;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh #FFE849, 0 0 0 1.5vh black;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #FFD700;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            width: 5vh;
            height: 5vh;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #D7364D;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        label[for="checkbox"] {
            padding-left: 2vh;
            font-size: 3.5vh;
        }

        .buttonText {
            .face {
                //width: 100%;
            }
        }
    }

    .playersSection {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 8%;
        display: flex;
        gap: 2vw;
        justify-content: center;
        transform: translateY(300%);
        transition: transform 0.3s;

        &.show {
            transform: translateY(0);
        }

        .team {
            position: relative;
            display: flex;

            &.buzzedIn {
                .podium {
                    background-color: var(--team-colour);
                }
            }

            .podium {
                position: absolute;
                background-color: #222758;
                width: calc(100% - 4vw);
                left: 2vw;
                height: 100%;
                box-shadow: 0 0 0 0.75vh black;
                transition: background-color 0.3s;

                .score {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    font-size: 5vh;
                    font-family: "Impact";
                    line-height: 1;
                    margin-bottom: 0.5vh;
                    min-width: 5vw;
                    color: $white;
                    text-align: center;
                    -webkit-text-stroke: 0.5vw black;
                    color: black;

                    .face {
                        font-size: 5vh;
                        font-family: "Impact";
                        position: absolute;
                        bottom: 0;
                        top: 0;
                        left: 0;
                        right: 0;
                        color: white;
                        -webkit-text-stroke: initial;
                        margin: 0;
                    }
                }
            }

            .player {
                position: relative;
                top: -185%;
                width: 10vw;
            }
        }
    }

    .playerTag {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 100%;
        font-family: "Futura-PT";
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0.5vh;
        min-width: 5vw;
        width: fit-content;
        height: fit-content;
        background-color: white;
        border-radius: 0.5vh;
        text-align: center;
        box-shadow: 0 0 0 0.5vh black, 0 0 0 1vh var(--team-colour), 0 0 0 1.5vh black;
        animation: pop cubic-bezier(.17,.67,.42,1.33) forwards 0.3s;
        animation-composition: add;

        @keyframes pop {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
    }

    .winnersSection {

        .titles {
            top: 18%;

            .title {
                width: 90vw;
            }
        }

        .trophy {
            position: absolute;
            left: 50%;
            bottom: 10vh;
            height: 30vh;
            transform: translate(-50%, 60vh);
            transition: transform 0.3s;
        }

        .callout {
            position: absolute;
            bottom: 2vh;
            font-size: 3vh;
            left: 50%;
            transform: translate(-50%, 50vh);
        }

        .winningPlayers {
            position: absolute;
            bottom: -7vh;
            left: 50%;
            transform: translate(-50%, 50vh);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: space-evenly;
            gap: 1vw;
            background-color: #060922;
            width: 100%;
            height: 17vh;
            transition: transform 0.3s;

            .player {
                transform: translateY(20vh);
                transition: transform 0.3s;
            }

            :nth-child(1), :nth-last-child(1) {
                transition-delay: 0.1s;
            }


            @for $i from 1 through 4 {
                div:nth-child(#{$i}), div:nth-last-child(#{$i}) {
                    transition-delay: 0.05s * $i;
                }
            }
        }

        &.show {
            .trophy {
                transform: translate(-50%, 0);
            }

            .callout {
                transform: translate(-50%, 0);
                transition-delay: 0.1s;
            }

            .winningPlayers {
                transform: translate(-50%, 0);

                .player {
                    transform: translateY(-13.5vh);
                }
            }
        }

        &.expand {
            .trophy {
                transform: translate(-50%, -7vh);
            }

            .callout {
                transform: translate(-50%, 50vh);
            }

            .winningPlayers {
                transform: translate(-50%, -7vh);

                .player {
                    transform: translateY(-13.5vh);
                }
            }
        }
    }

    .playAgainButtonSection {
        width: 55%;
        height: 14vh;
        margin: auto;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .mainButton {
            transform: translateY(20vh);
            transition: transform 0.3s;

            &:nth-child(2) {
                transition-delay: 0.05s;
            }
        }

        &.show {
            .mainButton {
                transform: translateY(0);
            }
        }
    }
}
