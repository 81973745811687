@import "../shared/variables.scss";

[contenteditable] {
    outline: 0px solid transparent;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    touch-action: manipulation;
}

img {
    touch-action: manipulation;
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    background-color: #1E236E;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    .logoSection {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        transition: top 0.3s;
        width: 100%;

        .logo {
            max-height: 35vh;
            max-width: 80%;
            height: auto;
        }

        &.show {
            top: 5%;
        }
    }

    .playerSection {
        position: absolute;
        height: fit-content;
        width: 50%;
        display: flex;
        flex-direction: column;
        left: 50%;
        top: 2vh;
        transform: translate(-50%, 0);
        transition: transform 0.3s, top 0.3s;

        &.center {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .startButtonSection {
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s;

        .mainButton {
            transform: translateY(60vh);
            transition: transform 0.3s;
        }

        .toggle {
            transform: translateY(60vh);
            transition: transform 0.3s;
        }

        &.show {
            .mainButton {
                transform: translateY(0);

                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
            }

            .toggle {
                transform: translateY(0);
                transition-delay: 0.2s;
            }
        }
    }

    .tutorialSection {
        .mainButton {
            position: absolute;
            left: 50%;
            bottom: -10%;
            transform: translateX(-50%);
            transition: transform 0.3s, bottom 0.3s;
            margin: initial;
        }

        &.show {
            .mainButton {
                bottom: 10%;
            }
        }
    }

    .gameSelectSection {
        position: absolute;
        width: 90%;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s;

        .instruction {
            transform: scale(0);
            font-size: 2vh;
            margin: 2vh;
            text-align: center;
        }

        .mainButton {
            transform: translateY(40vh);
            transition: transform 0.3s;
        }

        &.show {
            .mainButton {
                transform: translateY(0);

                &:nth-child(3) {
                    transition-delay: 0.1s;
                }
            }

            .instruction {
                transform: scale(1);
            }
        }
    }

    .teamSelect {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 90%;
        margin: 5%;
        text-align: center;
        height: fit-content;
        max-height: 70%;
        top: 55%;
        transform: translateY(-50%);

        .instruction {
            transform: scale(0);
            font-size: 2vh;
            margin: 1vh auto;
            text-align: center;
        }

        .teamSelectButtonWrapper {
            transform: scale(0);

            .teamSelectButton {
                margin: 1vh auto;
                width: 70%;
                height: fit-content;
                font-size: min(8vw, 5vh);
                color: black;
                background-color: var(--team-colour);
                text-transform: uppercase;
                box-shadow: 0 0 0 0.5vh black;
                border-radius: 1vh;
                padding: 3vh 1vh;
                margin-top: 2vh;
                cursor: pointer;
                pointer-events: initial;
                transition: transform 0.3s, box-shadow 0.3s;

                &.selected {
                    box-shadow: 0 0 0 0.5vh black, 0 0 0 1vh var(--team-colour), 0 0 0 1.5vh black;
                }
            }
        }

        .mainButton {
            position: relative;
            left: 50%;
            transform: translate(-50%, 50vh);
            max-width: 50%;
            min-width: fit-content;
            margin: 4vh 0vh;
            transition: transform 0.3s;
        }

        &.show {
            .teamSelectButtonWrapper {
                transform: scale(1);

                .teamSelectButton {
                    &.selected {
                        transform: scale(0.95);
                    }
                }

                @for $i from 5 through 1 {
                    &:nth-last-child(#{$i}) {
                        transition: transform 0.2s calc(0.1s * $i);
                    }
                }
            }

            .instruction {
                transform: scale(1);
            }

            .mainButton {
                &.show {
                    transform: translate(-50%, 0);
                }
            }
        }
    }

    .hiddenInput {
        position: absolute;
        /*        opacity: 0;
        filter: alpha(opacity=0);
        top:-100%;*/
        pointer-events: initial;
    }

    .catchphraseGrid {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 0;
        width: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s;

        &.show {
            transform: translate(-50%, -50%) scale(1);
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin: 1vh 0;

            .letter {
                font-size: min(7vw, 4vh);
                line-height: 1;
                color: black;
                text-align: center;
                width: 9%;
                aspect-ratio: 2/3;
                max-height: 7vh;
                border: 0.4vh solid black;
                border-radius: min(1.5vw, 1vh);
                margin: min(0.5vw, 0.3vh);
                padding: 1vh 0;
                cursor: pointer;
                pointer-events: initial;
                background-color: #00000044;
                color: white;
                transition: border 0.2s;
                caret-color: transparent;

                &:not(:disabled).selected {
                    border: 0.3vh solid var(--player-colour);
                    padding: 0.9vh 0;
                }

                &:disabled {
                    cursor: default;
                    pointer-events: none;
                }

                &:focus {
                    outline: none;
                    border: 0.4vh solid black;
                }
            }

            .space {
                width: 9%;
                aspect-ratio: 2/3;
                max-height: 7vh;
                margin: min(0.5vw, 0.3vh);
                background-color: transparent;
                border-radius: 1vh;
            }
        }
    }

    .timer {
        position: absolute;
        top: 22vh;
        width: min(20vw, 10vh);
        max-width: 20%;
        max-height: 6vh;
        font-family: "Impact";
        line-height: 1;
        padding: 1vh 0;
        font-size: min(8vw, 4vh);
        text-align: center;
        background-color: #0A0F41;
        color: white;
        left: 50%;
        border-radius: 0.5vh;
        box-shadow: 0 0 0 0.4vh black, 0 0 0 1vh #FFE849, 0 0 0 1.5vh black;
        transform: translate(-50%, 0) scale(0);
        transition: transform cubic-bezier(.45, -0.31, .97, .64) 0.3s, opacity 0.3s;
        opacity: 0;

        &.show {
            transform: translate(-50%, 0) scale(1);
            transition: transform cubic-bezier(.17, .67, .47, 1.14) 0.3s, opacity 0.3s;
            opacity: 1;
        }
    }

    .pass {
        position: absolute;
        right: 2vh;
        bottom: 2vh;
        width: min(10vh, 15vw);
        height: min(10vh, 15vw);
        font-size: min(2vh, 2vw);
        transform: translateX(200%);
        background-color: #FFD700;
        border: none;
        border-radius: 100%;
        box-shadow: 0.7vh 0.7vh 0px 0px #00000040, 0 0 3vh 1vh #00000077 inset, 0 0 0 0.4vh black, 0 0 0 1.2vh #3F48A1, 0 0 0 1.6vh black;
        pointer-events: initial;
        transition: box-shadow 0.2s, transform 0.3s;

        .text {
            font-size: min(3vh, 5vw);
            -webkit-text-stroke: 0.6vh black;
            color: black;
        }

        &:active {
            box-shadow: 0 0 0 0 #00000040, 0 0 3vh 1vh #00000099 inset, 0 0 0 0.4vh black, 0 0 0 1.2vh #3F48A1, 0 0 0 1.6vh black;
        }

        &:disabled {
            background-color: lightgray;
            pointer-events: none;
        }

        &.show {
            transform: translateX(0);
        }
    }

    .buzzer {
        position: absolute;
        background-color: #3F48A1;
        box-shadow: 0 0 0 0.6vh black;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50vh);
        height: 50vw;
        max-height: 20%;
        aspect-ratio: 2/3;
        transition: transform 0.3s;

        &.show {
            transform: translate(-50%, 0);
        }

        &.teamBuzzedIn {
            .buzzerOuter {
                background-color: var(--player-colour);
            }
        }

        .buzzedInPlayer {
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            font-size: 2.5vh;
            line-height: 1;
            padding: 1vh;
            border-radius: 1vh;
            opacity: 0;
            position: absolute;
            bottom: 0.8vh;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            width: max-content;

            &.show {
                opacity: 1;
            }
        }

        .buzzerOuter {
            position: absolute;
            top: -50%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 200%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            box-shadow: 0 0 0 0.6vh black;
            background-color: #3F48A1;
            transition: background-color 0.3s;

            button {
                position: absolute;
                margin: 7.5%;
                width: 85%;
                height: 85%;
                border-radius: 50%;
                background-color: red;
                border: 3px solid #000000;
                box-shadow: 1vh 1vh 0px 0px #00000040, 0 0 6vh 3vh #00000077 inset;
                pointer-events: initial;
                cursor: pointer;
                transition: box-shadow 0.2s, background-color 0.3s;

                .lockedMessage {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: min(6vh, 12vw);
                    color: white;
                    text-align: center;
                    opacity: 0;
                    transition: opacity 0.3s;
                    font-family: "Impact";
                    color: black;
                }

                .cooldown {
                    position: absolute;
                    bottom: 10%;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 4vh;
                    color: white;
                    text-align: center;
                    opacity: 0;
                    transition: opacity 0.3s;
                    font-family: "Impact";
                    color: black;

                    &.show {
                        opacity: 0.4;
                    }
                }

                &:active {
                    box-shadow: 0 0 0 0 #00000040, 0 0 6vh 3vh #00000099 inset;
                }

                &:disabled {
                    background-color: lightgray;
                    pointer-events: none;

                    .lockedMessage {
                        opacity: 0.4;
                    }
                }
            }
        }
    }

    .keyboard {
        position: absolute;
        bottom: 1.2vh;
        left: 50%;
        transform: translate(-50%, 50vh);
        width: 100%;
        transition: transform 0.3s;
        background-color: grey;
        border-radius: 0;
        box-shadow: 0 0 0 0.4vh black, 0 0 0 0.8vh var(--player-colour), 0 0 0 1.2vh black;

        .buttonHolder {
            position: absolute;
            top: max(-5.5vh, -10vw);
            right: 1vh;
            width: fit-content;
            height: min(4.7vh, 8vw);
            background-color: var(--player-colour);
            border-radius: 1vh 1vh 0 0;
            box-shadow: 0 0 0 0.4vh var(--player-colour), 0 0 0 0.7vh black;
            transition: box-shadow 0.2s;

            &.left {
                right: auto;
                left: 1vh;
            }

            .button {
                position: relative;
                background-color: #b30000;
                color: white;
                font-family: "Impact";
                font-size: min(3vh, 5vw);
                line-height: 1;
                border-radius: 1vh;
                border: none;
                padding: 0.5vh;
                border: 0.3vh solid black;
                box-shadow: 0 0.3vh 0 0 black;
                cursor: pointer;
                pointer-events: initial;
                transition: background-color 0.1s, box-shadow 0.1s, transform 0.1s, color 0.1s;

                &.green {
                    background-color: #00b300;
                }

                &:active {
                    background-color: #FFD700;
                    box-shadow: 0 0 0 0 black;
                    transform: translateY(0.3vh);
                }

                &:disabled {
                    background-color: dimgrey;
                    pointer-events: none;
                    color: darkgray;
                }
            }
        }

        &.show {
            transform: translate(-50%, 0);
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 1vh 0;
            gap: 0.5%;

            .key {
                width: 9.5%;
                aspect-ratio: 2/3;
                max-height: 9vh;
                font-size: min(5vw, 24px);
                border: 0.3vh solid black;
                box-shadow: 0 0.3vh 0 0 black;
                border-radius: min(1vh, 2vw);
                cursor: pointer;
                pointer-events: initial;
                padding: 0;
                font-family: "Futura-PT";
                font-weight: bold;
                transition: background-color 0.1s, box-shadow 0.1s, transform 0.1s;

                &.wide {
                    width: 14%;
                    aspect-ratio: unset;
                }

                &:active {
                    background-color: #FFD700;
                    box-shadow: 0 0 0 0 black;
                    transform: translateY(0.3vh);
                }

                &:disabled {
                    background-color: dimgrey;
                    pointer-events: none;
                }

                &.gap {
                    opacity: 0;
                    cursor: none;
                    pointer-events: none;
                }
            }
        }
    }

    .roundIntroSection {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: transform 0.3s;

        .instruction {
            transform: scale(0);
            font-size: 2vh;
            margin: 2vh;
            text-align: center;
        }

        .mainButton {
            transform: translateY(40vh);
            transition: transform 0.3s;
        }

        &.show {
            .mainButton {
                &.show {
                    transform: translateY(0);
                }
            }

            .instruction {
                transform: scale(1);
            }
        }
    }

    .titles {
        position: relative;
        font-family: "Impact";
        /*        top: 50%;
        left: 50%;
        transform: translateX(-50%);*/
        color: $white;
        padding: 15px;
        margin: 0;
        line-height: 0.9;
        font-size: 3vw;
        text-align: center;

        .title {
            font-size: 12vh;
            -webkit-text-stroke: 1.3vh black;
            transform: scale(0);
            opacity: 0;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3), opacity 0.3s;
        }

        .subTitle {
            font-size: 3vh;
            -webkit-text-stroke: 0.6vh black;
            transform: scale(0);
            opacity: 0;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3), opacity 0.3s;
        }
    }

    .show {
        .title {
            transform: scale(1);
            opacity: 1;
            transition: transform 0.3s 0.2s cubic-bezier(.17, .67, .2, 1.3), opacity 0.3s 0.2s;
        }

        .subTitle {
            transform: scale(1);
            opacity: 1;
            transition: transform 0.3s cubic-bezier(.17, .67, .2, 1.3), opacity 0.3s;
        }
    }

    .roundSummarySection {
        position: absolute;
        width: fit-content;
        padding: 0vh 5vh;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #0d1352;
        border-radius: 2vh;
        border: 0.5vh solid black;

        .instruction {
            font-size: 2.5vh;
            margin: 2vh;
            text-align: center;
            white-space: nowrap;
        }

        .score {
            font-family: "Impact";
            font-size: 6vh;
            -webkit-text-stroke: 0.6vh black;
            margin: 2vh;
            text-align: center;
            transition: transform 0.3s, opacity 0.3s;
            text-transform: capitalize;
        }

        &.show {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    .revealAnswerSection {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .instruction {
            transform: scale(0);
            opacity: 0;
            font-size: 3vh;
            -webkit-text-stroke: 0.6vh black;
            margin: 2vh;
            text-align: center;
            transition: transform 0.3s, opacity 0.3s;
        }

        .answer {
            transform: scale(0);
            opacity: 0;
            font-family: "Impact";
            font-size: 5vh;
            -webkit-text-stroke: 0.6vh black;
            margin: 2vh;
            text-align: center;
            transition: transform 0.3s, opacity 0.3s;
/*            text-transform: capitalize;

            .face {
                text-transform: capitalize;
            }*/
        }

        .mainButton {
            transform: translateY(40vh);
            transition: transform 0.3s;
        }

        &.show {
            .mainButton {
                transform: translateY(0);
            }

            .instruction {
                transform: scale(1);
                opacity: 1;
            }

            .answer {
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    .endGameSection {
        width: 100%;
        height: 100%;
        white-space: nowrap;

        .comment {
            position: absolute;
            top: 24%;
            left: 50%;
            transform: translateX(-50%) scale(0);
            text-align: center;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3);
        }

        .winnerMessage {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%) scale(0);
            text-align: center;
            font-size: 6vh;
            font-family: Impact;
            -webkit-text-stroke: 1.2vh black;
            transition: transform 0.3s cubic-bezier(.8, -0.3, .67, 0.3);
        }

        .buttons {
            position: absolute;
            top: 47%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .mainButton {
                transform: translateX(-100vw);
                transition: transform 0.3s cubic-bezier(1, 0, 1, -4)
            }
        }

        .trophy {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 32%;
            transition: transform 0.3s;
            transition-delay: 0.1s;
        }

        &.show {

            .comment {
                transform: translateX(-50%) scale(1);
                transition: transform 0.3s cubic-bezier(.17, .67, .2, 1.3);
            }

            .winnerMessage {
                transform: translateX(-50%) scale(1);
                transition: transform 0.3s cubic-bezier(.17, .67, .2, 1.3);
                transition-delay: 0.3s;
            }

            .buttons {
                &.show {
                    .mainButton {
                        transform: translateX(0);
                        transition: transform 0.3s;

                        &:nth-child(2) {
                            transition-delay: 0.1s;
                        }
                    }
                }
            }

            .trophy {
                &.show {
                    transform: translate(-50%, 0);
                }
            }
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 4vh;
            color: white;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
            pointer-events: initial;
        }
    }

    .mainButton {
        position: relative;
        padding: 0.5vh;
        margin: 2vh;
        min-width: min(30vh, 50vw, 250px);
        font-family: "Futura-PT";
        background-color: #D7364D;
        border-radius: 0.5vh;
        border: none;
        box-shadow: 0 0 0 0.4vh black, 0 0 0 0.8vh #FFE849, 0 0 0 1.2vh black;
        transition: background-color 0.3s;
        white-space: nowrap;
        pointer-events: initial;

        &.alt {
            background-color: #B8DEE8;
        }

        &:not(:disabled):hover {
            background-color: #FFD700;

            .text {
                transform: translateY(-0.2vh);
            }
        }

        &.smaller {
            .text {
                font-size: 3vh;
                -webkit-text-stroke: 0.6vh black;
            }
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .text {
        position: relative;
        font-size: 3vh;
        //font-family: "Futura-PT";
        //font-weight: bold;
        line-height: 1;
        color: black;
        -webkit-text-stroke: 0.7vh black;
        transition: transform 0.3s;
        pointer-events: none;

        .face {
            position: absolute;
            color: white;
            -webkit-text-stroke: initial;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            color: white;
        }
    }

    .toggle {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin: 2vh;
        pointer-events: initial;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: white;
            margin: 0;
            font: inherit;
            width: 4vh;
            height: 4vh;
            border-radius: 0.5vh;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            box-shadow: 0 0 0 0.4vh black, 0 0 0 0.8vh #FFE849, 0 0 0 1.2vh black;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #FFD700;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            width: 5vh;
            height: 5vh;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #D7364D;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        label[for="checkbox"] {
            padding-left: 2vh;
            font-size: 2.5vh;
            white-space: nowrap;
        }
    }
}
