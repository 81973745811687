.player {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: 0.3s height, 0.3s transform;
    transform: scale(0);
    pointer-events: initial;
    cursor: pointer;

    &.show {
        transform: scale(1);
    }

    &.large {
        transform: scale(1.3);
    }

    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        left: -2.5vh;
        width: auto;
    }

    .pass {
        position: absolute;
        right: -15%;
        top: 15%;
        background-color: #FFD700;
        border-radius: 1.5vh 1.5vh 1.5vh 0;
        font-size: 2vh;
        font-family: "Futura-PT";
        font-weight: bold;
        padding: 0.5vh 1vh;
        box-shadow: 0 0 0 0.4vh black;
        transform-origin: 0 100%;
        transform: scale(0) rotate(45deg);
        transition: transform cubic-bezier(.8, -0.3, .67, 0.3) 0.3s;

        &.show {
            transition: transform cubic-bezier(.17, .67, .2, 1.3) 0.3s;
            transform: scale(1) rotate(0deg);
        }
    }

    .textSection {
        position: absolute;
        bottom: -5%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);

        .name {
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 1.5vh;
            font-family: "Futura-PT";
            line-height: 1;
            color: black;
            text-transform: uppercase;
            font-weight: bold;
            padding: 1vh 0.5vh;
            min-width: min(30vh, 70%);
            width: fit-content;
            background-color: white;
            border-radius: 0.5vh;
            text-align: center;
            box-shadow: 0 0 0 0.5vh black, 0 0 0 1vh var(--team-colour), 0 0 0 1.4vh black;
            transition: background-color 0.3s, box-shadow 0.3s;
        }

        .buzzer {
            position: absolute;
            top: -3.75vh;
            left: 50%;
            transform: translateX(-50%) scaleY(0.7);
            width: 4vh;
            border-radius: 50% 50% 0 0;
            aspect-ratio: 1.2/1;
            background-color: var(--team-colour);
            box-shadow: 0 0 0 0.5vh black;
            transition: background-color 0.3s, box-shadow 0.3s;

            .line {
                position: relative;
                height: 0.2vh;
                width: 100%;
                background-color: black;
                top: 35%;
            }
        }

        .points {
            color: black;
            font-size: 2.5vh;
            /*font-family: "Resistenza";*/
        }
    }

    &.lockedOut {
        .buzzer {
            background-color: grey;
        }

        .name {
            background-color: darkgray;
        }
    }

    &.isBuzzedIn {
        .name {
            background-color: white;
        }

        .buzzer {
            background-color: red;
            animation: buzz 0.5s infinite, press 0.5s forwards;

            @keyframes buzz {
                0% {
                    box-shadow: 0 0 0 0.5vh black, 0 0 2vh 0vh red;
                }

                50% {
                    box-shadow: 0 0 0 0.5vh black, 0 0 3vh 1vh red;
                }

                100% {
                    box-shadow: 0 0 0 0.5vh black, 0 0 2vh 0vh red;
                }
            }

            @keyframes press {
                0% {
                    transform: translate(-50%, 0) scaleY(0.7);
                }

                50% {
                    transform: translate(-50%, 20%) scaleY(0.7);
                }

                100% {
                    transform: translate(-50%, 0) scaleY(0.7);
                }
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        .textSection {
            .name {
                text-align: end;
            }

            .points {
                text-align: end;
            }
        }
    }
}
